html {
  body {
    overflow: overlay;
    overflow-x: hidden;
  }

  body::-webkit-scrollbar {
    width: 7px;
  }

  body::-webkit-scrollbar-thumb {
    background-color: rgb(177, 174, 174);
    border-radius: 6px;
  }

  .pdf-document {
    width: 100%;
  }

  scroll-behavior: auto;
}