.wrapper {
  display: flex;
  flex-direction: column;
  gap: 60px;
  font-family: "Golos Text", sans-serif;
  * {
    user-select: none;
  }
  .head {
    font-size: 44px;
    font-weight: 600;
    line-height: 52.8px;
    text-align: left;
    max-width: 60%;
    text-wrap: balance;
    .purple {
      background: rgba(148, 105, 227, 1);
      border-radius: 14px;
      color: white;
      padding: 0 10px;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    gap: 120px;
    .card {
      display: flex;
      justify-content: space-between;
      .img {
        flex: 1 1 750px;
        height: 490px;
        border-radius: 32px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .helper_img{
        flex: none;
        height: 612px;
      }
      .block {
        display: flex;
        flex-direction: column;
        flex: 0 0 515px;
        gap: 24px;
        background-color: rgba(25, 25, 25, 1);
        padding: 32px;
        border-radius: 32px;
        overflow: hidden;
        color: rgba(255, 255, 255, 1);
        .title {
          font-size: 28px;
          font-weight: 500;
          line-height: 33.6px;
          text-align: left;
        }
        .text {
          font-size: 18px;
          font-weight: 400;
          line-height: 25.2px;
          text-align: left;
        }
        .button {
          border: none;
          background: rgba(148, 105, 227, 1);
          padding: 8px 22px;
          border-radius: 4px;
          width: fit-content;
          font-size: 15px;
          font-weight: 400;
          line-height: 28px;
          text-align: center;
        }
      }
      .helper {
        justify-content: center;
        background-color: transparent;
        color: rgba(0, 0, 0, 1);
        .title {
          font-size: 36px;
          font-weight: 700;
          line-height: 46.8px;
          text-align: left;
        }
        .text {
          font-size: 18px;
          font-weight: 400;
          line-height: 25.2px;
          text-align: left;
        }
      }
    }
  }
}
