.container {
  display: flex;
  height: 378px;
  z-index: 1;
  font-family: "Golos Text", sans-serif;
  *{
    user-select: none;
  }
  .carousel {
    display: flex;
    flex: 1 0 480px;

  }
  .platonus {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 100%;
    border-radius: 24px;
    background: linear-gradient(
      233.33deg,
      #ffffff -150.62%,
      #dcacff -69.55%,
      #ad68f3 6.2%,
      #2b2883 76.15%,
      #0c0c0c 138.84%
    );
  }
  .img{
    width: 100%;
    height: 100%;
    border-radius: 20px;
    overflow: hidden;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
  }
  .content {
    padding: 85px 60px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .title {
      font-size: 36px;
      font-weight: 600;
      line-height: 43.2px;
      text-align: left;
    }
    .text {
      font-size: 16px;
      font-weight: 400;
      line-height: 20.8px;
      text-align: left;
      color: #8e8e93;
    }
    .buttons {
      display: flex;
      align-items: center;
      gap: 16px;
      .dots {
        display: flex;
        gap: 6px;
        .dot {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #d9d9d9;
          cursor: pointer;
          &:hover {
            background: #9469e3;
          }
        }
        .active{
            background: #9469e3;
        }
      }
    }
  }
}
