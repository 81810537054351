.wrapper {
  display: flex;
  flex-direction: column;
  gap: 60px;
  font-family: "Golos Text", sans-serif;
  *{
    user-select: none;
  }
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: 44px;
      font-weight: 600;
      line-height: 52.8px;
      text-align: left;
      max-width: 50%;
      text-wrap: balance;
    }
    .button {
      border: none;
      background: rgba(148, 105, 227, 1);
      padding: 8px 22px;
      border-radius: 4px;

      font-size: 15px;
      font-weight: 400;
      line-height: 28px;
      text-align: left;
      color: rgba(255, 255, 255, 1);
    }
  }
  .content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 16px;
    .card {
      padding: 24px;
      background-color: rgba(245, 245, 245, 1);
      border: 1px solid rgba(0, 0, 0, 0.05);
      border-radius: 24px;
      display: flex;
      flex-direction: column;
      transition: all 0.3s ease-out;
      &:hover{
        transform: scale(1.02);
      }
      gap: 20px;
      .img {
        height: 200px;
        width: 100%;
        border-radius: 24px;
        overflow: hidden;
        border: 1px solid rgba(0, 0, 0, 0.15);
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
      .block {
        display: flex;
        flex-direction: column;
        gap: 8px;
        .text {
          font-size: 22px;
          font-weight: 600;
          line-height: 26.4px;
          text-align: left;
        }
        .subtext {
          font-size: 18px;
          font-weight: 400;
          line-height: 25.2px;
          text-align: left;
          span {
            text-decoration: underline;
            color: rgba(148, 105, 227, 1);
            cursor: pointer;
          }
        }
      }
    }
  }
}
