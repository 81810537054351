.footer {
  font-family: "Golos Text", sans-serif;
  .container{
    background-color: rgba(25, 25, 25, 1);
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 100px;
    padding: 80px 0px 20px 0px;
    @media screen and (max-width: 1440px) {
      gap: 60px;
    }

    .content {
      // display: flex;
      // flex-direction: row;
      // justify-content: space-between;
      display: grid;
      grid-gap: auto;
      grid-template-columns: repeat(4,1fr);
      grid-template-areas: "l c h s";
      @media screen and (max-width: 960px) {
        grid-template-columns: 1fr 1fr;
        grid-template-areas: "l l"
        "c h"
        ". s";
      }
      @media screen and (max-width: 468px) {
        grid-template-columns: 1fr ;
        grid-template-areas: "l"
        "c"
        "h"
        "s";
      }
      .logo {
        display: flex;
        gap: 24px;
        grid-area: l;
        svg{
          cursor: pointer;
        }
        @media screen and (max-width: 960px) {
          margin-bottom: 70px;
        }
      }
      .title {
        color: rgba(255, 255, 255, 1);
        font-size: 18px;
        font-weight: 500;
        line-height: 21.6px;
        text-align: left;
        user-select: none;
        @media screen and (max-width: 468px) {
          font-size: 14px;
          line-height: 18.2px;
        }
      }
      .catalog {
        grid-area: c;
        display: flex;
        flex-direction: column;
        gap: 20px;
       
        .list {
          text-decoration: none;
          list-style: none;
          padding: 0;
          margin: 0;
          display: flex;
          flex-direction: column;
        
          gap: 12px;
          .item {
            color: rgba(142, 142, 147, 1);
            font-size: 18px;
            font-weight: 500;
            line-height: 21.6px;
            text-align: left;
            cursor: pointer;
            user-select: none;
            &:hover {
              color: rgba(255, 255, 255, 1);
            }
            @media screen and (max-width: 468px) {
              font-size: 14px;
              line-height: 18.2px;
            }
          }
        }
      }
      .catalog2 {
        grid-area: h;
        display: flex;
        flex-direction: column;
        gap: 20px;
        @media screen and (max-width: 468px) {
          margin: 44px 0 32px;
        }
        .list {
          text-decoration: none;
          list-style: none;
          padding: 0;
          margin: 0;
          display: flex;
          flex-direction: column;
        
          gap: 12px;
          .item {
            color: rgba(142, 142, 147, 1);
            font-size: 18px;
            font-weight: 500;
            line-height: 21.6px;
            text-align: left;
            cursor: pointer;
            user-select: none;
            &:hover {
              color: rgba(255, 255, 255, 1);
            }
            @media screen and (max-width: 468px) {
              font-size: 14px;
              line-height: 18.2px;
            }
          }
        }
      }
      .social {
        grid-area: s;
        display: flex;
        flex-direction: column;
        gap: 20px;
        .divider{
            display: flex;
            gap: 4px;
            .icon{
                background-color: rgba(47, 43, 54, 1);
                width: 36px;
                height: 36px;
                border-radius: 7px;
                display: flex;
                justify-content: center;
                align-items: center;
                fill:white;
                cursor: pointer;
                &:hover{
                    fill:rgba(148, 105, 227, 1)
                }
            }
        }
      }
    }
    .time {
      display: flex;
      justify-content: center;
      align-items: center;
      color: rgba(255, 255, 255, 1);
      font-size: 14px;
      font-weight: 400;
      line-height: 16.8px;
      text-align: center;
    }
  }
}

