.container{
    max-width: 1920px;
    width: 100%;
    margin: 0 auto;
    padding-left: calc((100vw - 1216px) / 2);
    padding-right: calc((100vw - 1216px) / 2);
    display: flex;
    flex-direction: column;
    gap: 120px;
    @media screen and (max-width: 1440px) {
        padding: 0 120px;
    }
    @media screen and (max-width: 1220px) {
        padding: 0 56px;
    }

    *{
        user-select: none;
    }
}