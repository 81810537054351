.parent {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    border-radius: 32px;
    overflow: hidden;
    cursor: pointer;
    &:hover .big,
    &:focus .big {
      -ms-transform: scale(1.2);
      -moz-transform: scale(1.2);
      -webkit-transform: scale(1.2);
      -o-transform: scale(1.2);
      transform: scale(1.2);
    }
  
    &:hover .big:before,
    &:focus .big:before {
      display: block;
    }
  
    &:hover .small,
    &:focus .small {
      -ms-transform: scale(1.2);
      -moz-transform: scale(1.2);
      -webkit-transform: scale(1.2);
      -o-transform: scale(1.2);
      transform: scale(1.2);
    }
  
    &:hover .small:before,
    &:focus .small:before {
      display: block;
    }
  
    .big {
      height: 330px;
      border-radius: 20px;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      backface-visibility: hidden;
      -webkit-transition: all 0.5s;
      -moz-transition: all 0.5s;
      -o-transition: all 0.5s;
      transition: all 0.5s;
      overflow: hidden;
      background-position: center;
      @media (max-width: 1440px) {
        height: 310px;
      }
  
      @media (max-width: 1280px) {
        height: 300px;
      }
    }
  
    .small {
      height: 160px;
      border-radius: 20px;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      backface-visibility: hidden;
      -webkit-transition: all 0.5s;
      -moz-transition: all 0.5s;
      -o-transition: all 0.5s;
      transition: all 0.5s;
      overflow: hidden;
      background-position: center;
      @media (max-width: 1440px) {
        height: 150px;
      }
  
      @media (max-width: 1280px) {
        height: 135px;
      }
    }
    .content_text{
      position: absolute;
      top: 24px;
      left: 24px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      .head {
     
        font-family: "Golos Text", sans-serif;
        font-size: 24px;
        font-weight: 500;
        line-height: 28.8px;
        text-align: left;
        color: rgba(255, 255, 255, 1);
        z-index: 1;
      }
    
      .text {
        border-radius: 50px;
        padding: 2px 16px;
        background: rgba(255, 255, 255, 0.2);
        font-family: "Golos Text", sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: rgba(255, 255, 255, 1);
        width: fit-content;
      }
    }
  
    
  
    .purple {
      display: flex;
      justify-content: center;
      align-items: center;
      gap:12px;
  
      background-color: rgba(148, 105, 227, 1);
      color: rgba(255, 255, 255, 1);
      font-size: 24px;
      font-weight: 500;
      line-height: 28.8px;
      text-align: left;
  
      height: 100%;
      width: 100%;
      &:hover{
        background-color:rgba(122, 73, 212, 1);
      }
      
    }
}