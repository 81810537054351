.container {
  display: flex;
  flex-direction: column;
  gap: 60px;
  font-family: "Golos Text", sans-serif;
  *{
    user-select: none;
  }
  .head {
    display: flex;
    justify-content: space-between;

    .title {
      font-family: "Golos Text", sans-serif;
      font-size: 44px;
      font-weight: 600;
      line-height: 52.8px;
      text-align: left;
    }

    .button {
      border-radius: 4px;
      background-color: rgba(148, 105, 227, 1);
      padding: 8px 12px;
      font-family: "Golos Text", sans-serif;
      font-size: 15px;
      font-weight: 400;
      line-height: 28px;
      text-align: left;
      display: flex;
      justify-content: center;
      align-items: center;
      color: rgba(255, 255, 255, 1);
    }
  }

  .wrapper {
    display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
  }
}
