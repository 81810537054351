.wrapper {
  display: flex;
  gap: 92px;
  font-family: "Golos Text", sans-serif;
  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 12px;
    .big {
      background-color: #f6f4fe;
      display: flex;
      border-radius: 24px;
      position: relative;
      .big_content {
        display: flex;
        flex-direction: column;
        gap: 25px;
        padding: 24px;
      }
      .img {
        padding: 34px 43px 0 0;
        width: 100%;
        .img_content {
          height: 100%;
          width: 188px;
          img {
            width: 100%;
            height: 100%;
            object-fit: fill;
          }
        }
      }
    }
    .small {
      display: flex;
      gap: 12px;
      flex-direction: row;
      .small_content {
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 24px;
        background-color: #eadfff;
        border-radius: 24px;
      }
    }
  }
  .head {
    flex: 1;
  }
}

.title {
  font-size: 44px;
  font-weight: 600;
  line-height: 52.8px;
  text-align: left;
}
.text_content {
  display: flex;
  flex-direction: column;
  gap: 4px;
  .text {
    font-size: 18px;
    font-weight: 600;
    line-height: 25.2px;
    text-align: left;
    text-wrap: nowrap;
  }
  .subtext {
    font-size: 16px;
    font-weight: 400;
    line-height: 20.8px;
    text-align: left;
  }
}

.purple {
  color: #887cf0;
}

.white {
  color: white;
}
.back_purple {
  background-color: #9469e3!important;
  *{
    color: white;
  }
}
