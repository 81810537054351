.wrapper {
  display: flex;
  flex-direction: row;
  font-family: "Golos Text", sans-serif;
  background-color: rgba(25, 25, 25, 1);
  box-shadow: 0 0 0 100vmax rgba(25, 25, 25, 1);
  clip-path: inset(0 -100vmax);
  gap: 8px;
  .content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    color: rgba(255, 255, 255, 1);
    padding: 78px 0;
    flex: 1;
    .title {
      font-size: 44px;
      font-weight: 600;
      line-height: 52.8px;
      text-align: left;
    }
    .text {
      font-size: 18px;
      font-weight: 400;
      line-height: 25.2px;
      text-align: left;
    }
    .button {
      border: none;
      background: rgba(148, 105, 227, 1);
      padding: 8px 22px;
      border-radius: 4px;
      width: fit-content;
      font-size: 15px;
      font-weight: 400;
      line-height: 28px;
      text-align: center;
    }
  }
  .block {
    flex: 1;
    position: relative;
    color: rgba(255, 255, 255, 1);
    padding: 0 70px;
    .helper {
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      padding: 12px 16px;
      border-radius: 36px;
    }

    .text {
      position: absolute;
      bottom: 0px;
      background-color: rgba(39, 39, 230, 1);
    }
    .text1 {
      position: absolute;
      bottom: 14px;
      right: 6px;
      background-color: rgba(192, 39, 230, 1);
      transform: rotate(6.49deg);
    }
    .text2 {
      position: absolute;
      bottom: 52px;
      background-color: rgba(230, 39, 131, 1);
    }
    .text3 {
      position: absolute;
      bottom: 73px;
      right: 48px;
      background-color: rgba(39, 39, 230, 1);
      transform: rotate(-5.94deg);
    }
    .text4 {
      position: absolute;
      bottom: 111px;
      left:116px;
      background-color: rgba(192, 39, 230, 1);
      transform: rotate(5.68deg);
    }
    .text5 {
      position: absolute;
      bottom: 141px;
      right: 66px;
      background-color: rgba(192, 39, 230, 1);
      transform: rotate(6.53deg);
    }
    .text6 {
        position: absolute;
        bottom: 194px;
        left: 147px;
        background-color: rgba(39, 39, 230, 1);
        transform: rotate(-12.97deg);
    }
    .text7 {
        position: absolute;
        bottom: 182px;
        right: 0;
        background-color: rgba(230, 39, 131, 1);
        transform: rotate(6.53deg);
    }
    .text8 {
        position: absolute;
        bottom: 250px;
        right: 10px;
        background-color: rgba(39, 39, 230, 1);
        transform: rotate(13.96deg);
      }
  }
}
