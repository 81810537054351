.rbc-row-segment {
  padding: 3px 10px;

  .rbc-selected {
    background-color: transparent;
  }
}

.rbc-toolbar {
  .rbc-toolbar-label {
    text-align: left;
  }
}

.rbc-event {
  background-color: transparent;

  .rbc-event-label {
    display: none;
  }
}

.rbc-day-slot {
  .rbc-events-container {
    margin-right: 0;
  }

  .rbc-event {
    border: none;

    &:focus {
      outline: 0;
    }
  }

  .rbc-selected {
    background-color: transparent;
  }
}

.rbc-event-general {
  background-color: #0EA5E91A;
  color: #0369A1;
  font-weight: 500;
  padding: 5px;
  border-radius: 4px;
  border-left: 3px solid #0EA5E9;
  height: 100%;
  
  &:hover {
    background-color: #0EA5E94D;
  }
}

.rbc-event-private {
  background-color: #10B9811A;
  color: #047857;
  font-weight: 500;
  padding: 5px;
  border-radius: 4px;
  border-left: 3px solid #047857;
  height: 100%;
  
  &:hover {
    background-color: #10B9814D;
  }
}

.rbc-event-conference {
  background-color: #8B5CF61A;
  color: #6D28D9;
  font-weight: 500;
  padding: 5px;
  border-radius: 4px;
  border-left: 3px solid #6D28D9;
  height: 100%;
  
  &:hover {
    background-color: #8B5CF64D;
  }
}

.rbc-event-task {
  background-color: #BE123C1A;
  color: #BE123C;
  font-weight: 500;
  padding: 5px;
  border-radius: 4px;
  border-left: 3px solid #BE123C;
  height: 100%;
  
  &:hover {
    background-color: #BE123C4D;
  }
}

.rbc-today {
  background-color: transparent;
}

.rbc-button-link {
  color: #8E8E93;
}

.rbc-show-more:hover, .rbc-show-more:focus {
  color: #9469E3;
}

.rbc-date-cell:nth-child(6),
.rbc-date-cell:nth-child(7) {
  .rbc-button-link {
    color: #9469E3;
  }
}

.rbc-now>.rbc-button-link {
  background-color: #9469E3;
  color: #FFFFFF;
  padding: 5px;
  border-radius: 4px;
}

.rbc-month-row {
  min-height: fit-content;
}

.rbc-day-bg {
  background-color: #FFFFFF;
}

/* Keep the existing styles for off-range days */
.rbc-off-range-bg {
  background-color: #E8E8E8 !important; /* Ensures it uses its default color */
}

/* Highlight weekends (Saturdays and Sundays) */
.rbc-month-view .rbc-day-bg:nth-child(6),
.rbc-month-view .rbc-day-bg:nth-child(7) {
  background-color: #FCFAFF;
}

.rbc-time-view {
  background-color: #FFFFFF;
}