.container{
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    gap: 120px;

    @media screen and (max-width: 1440px) {
        gap: 110px;
    }

    @media screen {
        gap: 100px;
    }
}