.info_wrapper{
  display: flex;
  flex-direction: column;
  background: #f6f4fe;
  border-radius: 24px;
  border: 1px solid #0000000d;
  overflow: hidden;
  flex: 1;
}
.info {
  display: flex;
  font-family: "Golos Text", sans-serif;
  flex-direction: column;
  gap: 44px;
  padding: 24px 20px 0 20px;
  flex: 1;
  .title {
    font-size: 28px;
    font-weight: 500;
    line-height: 33.6px;
    text-align: left;
  }
  .description {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .text {
      font-size: 18px;
      font-weight: 500;
      line-height: 21.6px;
      text-align: left;
    }
    .list {
      display: flex;
      gap: 8px;
      font-size: 16px;
      font-weight: 400;
      line-height: 20.8px;
      text-align: left;
    }
  }
}

.content {
  flex: 1;
}

.helper {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #8e8e93;
  span {
    color: #9469e3;
    text-decoration: underline;
    cursor: pointer;
  }
}
.img {
  height: 235px;
  img{
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
