.custom-editor-content {
  max-height: 350px;
  overflow-y: auto;
  border: 1px solid #e9ecef;
  border-radius: 6px;
  padding: 10px;
}

.custom-editor-content::-webkit-scrollbar{
    width: 3px;
}

.custom-editor-content::-webkit-scrollbar-thumb{
    background-color: lightgray;
    border-radius: 6px
}
