.wrapper {
  box-shadow: 4px 8px 16px 0px #001b6f33;
  border: 1px solid #e8e8e8;
  border-radius: 12px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  padding: clamp(0.625rem, 0.25rem + 2vw, 1.75rem);
  height: min-content;
  flex: 1;
  * {
    user-select: none;
  }
  @media screen and (max-width: 960px) {
    order: -1;
    margin-bottom: 20px;
  }
}

.logo {
  // width:calc(100vw * 233 / 1440);
  // height: calc(100vw * 75 / 1440);
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  // @media screen and (max-width: 764px) {
  //   width: 115px;
  //   height: 37px;
  // }
  // @media screen and (max-width: 462px) {
  //   width: 46px;
  //   height: 15px;
  // }
}

.head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  .org {
    display: flex;
    gap: clamp(0.625rem, 0.25rem + 2vw, 1.75rem);
    .org_img {
      // height:  calc(100vw * 75 / 1440);
      // width: calc(100vw * 75 / 1440);
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      // @media screen and (max-width: 764px) {
      //   width: 37px;
      //   height: 37px;
      // }
      // @media screen and (max-width: 462px) {
      //   width: 15px;
      //   height: 15px;
      // }
    }
  }
  .qr {
    // width: clamp(1.625rem, 0.6667rem + 5.1111vw, 4.5rem);
    // height: clamp(1.625rem, 0.6667rem + 5.1111vw, 4.5rem);
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    // @media screen and (max-width: 764px) {
    //   width: 64px;
    //   height: 64px;
    // }
    // @media screen and (max-width: 462px) {
    //   width: 26px;
    //   height: 26px;
    // }
  }
  .text {
    font-size: 8.8px;
    font-weight: 400;
    line-height: 11.44px;
    text-align: center;
    @media screen and (max-width: 764px) {
      font-size: 7.96px;
      font-weight: 400;
      line-height: 10.34px;
    }
    @media screen and (max-width: 462px) {
      font-size: 3.2px;
      font-weight: 400;
      line-height: 4.16px;
    }
  }
}

.content {
  // max-width: 60%;
  display: flex;
  flex-direction: column;
  gap:clamp(0.375rem, 0.125rem + 1.3333vw, 1.125rem);
  // @media screen and (max-width: 764px) {
  //   max-width: 60%;
  // }
  // @media screen and (max-width: 468px) {
  //   max-width: 60%;
  // }
  .title {
    font-size: clamp(0.375rem, 0.1563rem + 1.1667vw, 1.03125rem);
    font-weight: 500;
    line-height: clamp(0.4875rem, 0.2031rem + 1.5167vw, 1.340625rem);
    letter-spacing: 0.02em;
    color: #6b7189;
    // @media screen and (max-width: 764px) {
    //   font-size: 14.92px;
    //   line-height: 19.39px;
    // }
    // @media screen and (max-width: 462px) {
    //   font-size: 6px;
    //   line-height: 7.8px;
    // }
  }
  .subtitle {
    font-size: clamp(0.9625rem, 0.401rem + 2.9944vw, 2.646875rem);
    font-weight: 700;
    line-height:  clamp(0.98125rem, 0.4083rem + 3.0556vw, 2.7rem);
    letter-spacing: 0.04em;
    font-family: "Tinos", serif;
    font-style: normal;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-wrap: balance;
    // max-width: 60%;
   
    // @media screen and (max-width: 462px) {
    //   font-size: 15.4px;
    //   font-weight: 700;
    //   line-height: 15.71px;
    //   letter-spacing: 0.04em;
    // }
  }
}

.bottom {
  display: flex;
  justify-content: space-between;
  .info {
    display: flex;
    flex-direction: column;
    gap: 14px;
    justify-content: flex-end;
    @media screen and (max-width:462px) {
      gap: 5px;
    }
    .name {
      font-family: "Tinos", serif;
      font-size: 28.6px;
      font-weight: 700;
      line-height: 37.18px;
      letter-spacing: 0.06em;
      @media screen and (max-width: 764px) {
        font-size: 25.86px;
        line-height: 33.61px;
      }
      @media screen and (max-width: 462px) {
        font-size: 10.4px;
        line-height: 13.52px;
      }
    }

    .date {
      display: flex;
      gap: 33px;
      @media screen and (max-width: 468px) {
        gap: 12px;
      }
      .part {
        display: flex;
        flex-direction: column;
        gap: 11px;
        .block {
          display: flex;
          flex-direction: column;
          gap: 2px;
          .label {
            font-size: 8.8px;
            font-weight: 600;
            line-height: 11.44px;
            letter-spacing: 0.02em;
            @media screen and (max-width: 764px) {
              font-size: 7.96px;
              font-weight: 600;
              line-height: 10.34px;
              letter-spacing: 0.02em;
            }
            @media screen and (max-width: 462px) {
              font-size: 3.2px;
              line-height: 4.16px;
            }
          }
          .value {
            font-size: 8.8px;
            font-weight: 500;
            line-height: 11.44px;
            letter-spacing: 0.02em;
            color: #6b7189;
            @media screen and (max-width: 764px) {
              font-size: 7.96px;
              font-weight: 500;
              line-height: 10.34px;
              letter-spacing: 0.02em;
            }
            @media screen and (max-width: 462px) {
              font-size: 3.2px;
              line-height: 4.16px;
            }
          }
        }
      }
    }

    .link {
      font-size: 8.8px;
      font-weight: 400;
      line-height: 11.44px;
      color: #9469e3;
      cursor: pointer;
      border-left: 2px solid #9469e3;
      padding-left: 4px;
      vertical-align: middle;
      @media screen and (max-width: 764px) {
        font-size: 7.96px;
        font-weight: 400;
        line-height: 10.34px;
      }
      @media screen and (max-width: 462px) {
        font-size: 3.2px;
        line-height: 4.16px; 
      }
    }
  }

  .sign {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 36px;
    @media screen and (max-width:462px) {
      gap: 13px;
    }
    .seal {
      width: 157px;
      height: 157px;
      @media screen and (max-width: 764px) {
        width: 142px;
        height: 142px;
      }
      @media screen and (max-width:462px) {
        width: 57px;
        height: 57px;
      }
      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
    .caption {
      display: flex;
      align-content: center;
      width: 130px;
      height: 42px;
      @media screen and (max-width: 764px) {
        width: 117px;
        height: 30px;
      }
      @media screen and (max-width:462px) {
        width: 47px;
        height: 12px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

.btn {
  display: none;
  @media screen and (max-width: 960px) {
    margin-bottom: 20px;
  }
}
