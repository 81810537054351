.title {
  font-size: 44px;
  font-weight: 600;
  line-height: 52.8px;
  text-align: left;
  font-family: "Golos Text", sans-serif;
}

.wrapper {
  display: grid;
  gap: 13px;
  grid-template-columns: repeat(4, 1fr);
  *{
    user-select: none;
  }
}

.smaller {
  display: flex;
  flex-direction: column;
  gap: 13px;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 48px;
  overflow: hidden;
}
