.head {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .title {
      font-size: 36px;
      font-weight: 700;
      line-height: 46.8px;
      text-align: left;
      text-wrap: balance;
    }
    .text {
      font-size: 18px;
      font-weight: 400;
      line-height: 25.2px;
      text-align: left;
      text-wrap: balance;
    }
  }