.wrapper {
  display: flex;
  font-family: "Golos Text", sans-serif;
  color:rgba(255, 255, 255, 1) ;
  background-color: rgba(25, 25, 25, 1);
  border-radius: 36px;
//   overflow: hidden;
//   -webkit-background-clip: padding-box; 
//   -moz-background-clip:    padding; 
//   background-clip:         padding-box;
//   position: static;
  .head {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 40px;
    
    .title {
      font-size: 36px;
      font-weight: 700;
      line-height: 46.8px;
      text-align: left;
    }
    .text {
      font-size: 18px;
      font-weight: 400;
      line-height: 25.2px;
      text-align: left;
    }

    .button {
      border: none;
      background: rgba(148, 105, 227, 1);
      padding: 8px 22px;
      border-radius: 4px;
        width: fit-content;
      font-size: 15px;
      font-weight: 400;
      line-height: 28px;
      text-align: center;
    }
  }
  .content{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 32px;
    overflow: hidden;   
     flex: 1 0 670px;
     position: static;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
  }
}
