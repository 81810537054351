.custom-editor {
  padding-left: 10px !important;
  padding-right: 10px !important;
  word-wrap: break-word;
  word-break: break-word;
  white-space: normal;
}

.custom-wrapper-diasbled {
  background-color: #E7E7E7;
  border: 1px solid rgba(0, 0, 0, 0.26);
  border-radius: 4px;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.38);
}

.custom-scroll-editor {
  max-height: 300px;
  overflow-y: auto;
}

.custom-scroll-editor::-webkit-scrollbar {
  width: 3px;
}

.custom-scroll-editor::-webkit-scrollbar-thumb {
  background-color: lightgray;
  border-radius: 6px
}

.custom-notes-scroll-editor {
  min-height: 300px;
  max-height: 450px;
  overflow-y: auto;
  background: #fff;
  padding-left: 10px;
}

.custom-notes-scroll-editor::-webkit-scrollbar {
  width: 3px;
}

.custom-notes-scroll-editor::-webkit-scrollbar-thumb {
  background-color: lightgray;
  border-radius: 6px
}

.hide-toolbar {
  display: none !important;
}

.rdw-image-modal-header {
  display: none;
}