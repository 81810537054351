.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  font-family: "Golos Text", sans-serif;
  .content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .title {
      font-size: 28px;
      font-weight: 500;
      line-height: 33.6px;
      text-align: center;
    }
    .text {
      font-size: 16px;
      font-weight: 400;
      line-height: 20.8px;
      text-align: center;
    }
  }
  .bottom {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .helper {
      font-size: 16px;
      font-weight: 400;
      line-height: 20.8px;
      text-align: center;
    }
    .info {
      font-size: 14px;
      font-weight: 400;
      line-height: 16.8px;
      text-align: left;
      color: #8e8e93;
      display: flex;
      text-wrap: nowrap;
    }
  }
}
