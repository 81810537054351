.wrapper {
  display: flex;
  flex-direction: column;
  padding: 12px;
  border: 1px solid rgba(232, 232, 232, 1);
  border-radius: 8px;
  height: 334px;
  width: 100%;
  font-family: "Golos Text", sans-serif;
  gap: 8px;
  transition: all 0.3s ease-out;
  // position: relative;
  cursor: pointer;
  overflow: hidden;
  &:hover {
    border: 1px solid rgba(148, 105, 227, 1);
    box-shadow: 5px 5px 54px 0px rgba(149, 116, 193, 0.2);
    transform: scale(1.02);
  }

  // &:hover .content{
  //   transform: translateY(-45px) ;
  // }
  // &:hover .img{
  //   height: 160px;
  // }
  .img {
    height: 195px;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    transition: all 0.3s ease-out;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .head {
      display: flex;
      align-items: center;
      gap: 8px;
      position: absolute;
      top: 8px;
      left: 8px;

      .star {
        padding: 4px 8px;
        display: flex;
        gap: 4px;
        display: flex;
        align-items: center;
        background: #ffffff;
        border: 1px solid #d2d5da;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 400;
        line-height: 13.2px;
        text-align: center;
      }

      .attention {
        display: flex;
        align-items: center;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: #9469e3;
        justify-content: center;
        font-size: 16px;
      }
    }

    .bookmark {
      position: absolute;
      top: 8px;
      right: 8px;
    }
  }
  .content {
    // position: absolute;
    top: 208px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 12px;
    transition: all 0.3s ease-out;
    .title {
      font-size: 16px;
      font-weight: 600;
      line-height: 20.8px;
      text-align: left;
      color: rgba(0, 0, 0, 1);
    }
    .info {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .price {
        font-size: 14px;
        font-weight: 500;
        line-height: 16.8px;
        text-align: left;
      }
      .tip {
        font-size: 12px;
        font-weight: 400;
        line-height: 14.4px;
        text-align: left;
        color: rgba(111, 111, 126, 1);
      }
    }

    .author {
      // display: none;
      display: flex;
      align-items: center;
      .avatar {
        max-width: 40px;
        width: 100%;
      }
      .name {
        font-size: 14px;
        font-weight: 400;
        line-height: 15.4px;
        text-align: left;
        display: -webkit-box;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
}

.img_text {
  position: absolute;
  top: 50%;  
  left: 50%; 
  transform: translate(-50%, -50%);
}
