.wrapper {
  display: flex;
  justify-content: space-between;
  font-family: "Golos Text", sans-serif;
  *{
    user-select: none;
  }
  .head {
    width: 520px;
  }
  .content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 588px;
    .card {
      display: flex;
      flex-direction: column;
      gap: 20px;
      border-radius: 24px;
      padding: 24px;
      background-color: rgba(246, 244, 254, 1);
      .icon {
        display: flex;
        width: 60px;
        height: 60px;
        justify-content: center;
        align-items: center;
        border-radius: 18px;
        background-color: white;
        box-shadow: 5px 6px 33.72px 0px rgba(0, 27, 111, 0.12);
      }
      .text_wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;
        .text {
          font-size: 22px;
          font-weight: 600;
          line-height: 26.4px;
          text-align: left;
        }
        .subtitle {
          font-size: 18px;
          font-weight: 400;
          line-height: 25.2px;
          text-align: left;
        }
      }
    }
  }
}
