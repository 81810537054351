.content {
  display: grid;
  width: 100%;
  overflow: hidden;
  grid-template-columns: 40px 1fr 40px;
  grid-row: auto;
  grid-template-areas: "l c r";
  height: 646px;
  column-gap: 8px;
  padding:0 4px;
  @media (max-width: 1440px) {
    height: 484px;
  }

  @media (max-width: 1280px) {
    height: 443px;
  }
  background-color: #FFFFFF;
  *{
    user-select: none;
  }
}

.item {
  width: 100%;
  grid-area: c;
  overflow: hidden;
  border-radius: 32px;
}

.wrapper {
  padding: 120px 160px;
  height: 100%;
  width: 100%;
  @media (max-width: 1440px) {
    padding: 120px 100px;
  }

  @media (max-width: 1280px) {
    padding: 100px 80px;
  }

  @media (max-width: 1024px) {
    padding: 80px 30px;
  }

  @media (max-width: 768px) {
    padding: 80px 30px;
  }
}

.left {
  grid-area: l;
  border: none;
  background: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border-radius: 32px;
  transition: transform 0.3s ease-out;
  
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-radius: 32px;
    transition: background-color 10s ease-out;
    pointer-events: none;
  }

  &:hover {
    transform: scaleX(1.1);
    background-color: #f1eef6;
  }
}

.right {
  grid-area: r;
  border: none;
  background: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border-radius: 32px;
  transition: transform 0.3s ease-out;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-radius: 32px;
    transition: background-color 10s ease-out;
    pointer-events: none;
  }

  &:hover {
    transform: scaleX(1.1);
    background-color: #f1eef6;
  }
}
.block {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.title {
  font-family: "Golos Text", sans-serif;
  font-size: 55px;
  font-weight: 600;
  line-height: 60.5px;
  letter-spacing: -0.02em;
  text-align: left;
  text-wrap: pretty;
  max-width: 61%;
  background: -webkit-linear-gradient(
    151deg,
    #fff 17.65%,
    rgba(236, 236, 236, 0) 164.69%
  );
  background: linear-gradient(
    151deg,
    #fff 17.65%,
    rgba(236, 236, 236, 0) 164.69%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 1440px) {
    font-size: 40px;
    font-weight: 600;
    line-height: 44px;
  }

  @media (max-width: 1280px) {
    font-size: 40px;
    font-weight: 600;
    line-height: 44px;
  }

  @media (max-width: 1024px) {
    font-size: 30px;
    font-weight: 600;
    line-height: 28px;
  }

  @media (max-width: 768px) { 
    font-size: 20px; 
    font-weight: 600;
    line-height: 24px;
  }
}

.text {
  font-family: "Golos Text", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  text-align: left;
  background: rgba(255, 255, 255, 1);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-wrap: balance;
  max-width: 51%;
  @media (max-width: 1440px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  @media (max-width: 1280px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}

.button {
  width: 195px;
  height: 62px;
  padding: 20px 36px 20px 36px;
  gap: 8px;
  border-radius: 12px;
  opacity: 0px;
  border: none;
  background: rgba(148, 105, 227, 1);
  cursor: pointer;
  font-family: "Golos Text", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.6px;
  text-align: left;
  color: rgba(255, 255, 255, 1);



  @media (max-width: 1024px) {
    font-size: 12px;
    font-weight: 500;
    line-height: 14.4px;
    padding: 8px 18px;
  }


  span{
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    z-index: 0;
    inset: 0px;
    border-radius: inherit;
  }
}

.balance{
  text-wrap: balance;
}


