.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  font-family: "Golos Text", sans-serif;
  .head {
    height: 63px;
  }
  .icon {
    position: absolute;
    top: 0; 
    left: 50%; 

    transform: translate(-50%, 0); 
    z-index: 1;
    padding: 24px;
    background-color: rgba(249, 250, 251, 1);
    border: 0.54px solid rgba(216, 217, 218, 1);
    box-shadow: 5.93px 6.86px 33.72px 0px rgba(0, 27, 111, 0.2);
    border-radius: 12px;
  }
  .icon2 {
    position: absolute;
    top: -1px;
    right: 0;
    z-index: 1;
    // transform: ;
  }
  .icon3 {
    position: absolute;
    bottom: -7px;
    left: 0;
    z-index: 1
  }
  .icon4 {
    position: absolute;
    top: -21px;
    left: 210px;
  }
  .icon5 {
    position: absolute;
    bottom: -100px;
    left: 234px;
  }
  .content {
    background-color: rgba(25, 25, 25, 1);
    padding: 140px 0;
    box-shadow: 0 0 0 100vmax rgba(25, 25, 25, 1);
    clip-path: inset(0 -100vmax);
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 24px;
    color: white;
    justify-content: center;
    align-items: center;
  }
  .title {
    font-size: 44px;
    font-weight: 600;
    line-height: 52.8px;
    text-align: center;
  }
  .text {
    font-size: 18px;
    font-weight: 400;
    line-height: 25.2px;
    text-align: center;
    max-width: 472px;
    text-wrap: balance;
  }
}
