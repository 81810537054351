.wrapper {
  display: flex;
  justify-content: space-between;
  gap:86px;
  overflow: hidden;
  background-color: rgba(246, 244, 254, 1);
  font-family: "Golos Text", sans-serif;
  box-shadow: 0 0 0 100vmax rgba(246, 244, 254, 1);
  clip-path: inset(0 -100vmax);
  padding: 80px 0;
  *{
    user-select: none;
  }
  .head {
    flex: 0 1 486px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    .title{
      font-size: 44px;
      font-weight: 600;
      line-height: 52.8px;
      text-align: left;
      text-wrap: balance;
    }
   
    
  }
  .content {
    flex: 1;
    .card {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .player {
        // height: 350px;
        display: flex;
        align-items: center;
        border-radius: 50px;
        border: 1px solid #E8E8E8;
        padding: 15px;
        background-color: #fff;
        overflow: hidden;
        width: 100%;
        position: relative;
        // background-color: black;

        .videoCover {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          border-radius: 50px;
          z-index: 1;
          width: 100%;
          height: 100%;
          padding: 15px;
        }

        .controller {
          width: 70px;
          height: 70px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: rgba(0, 0, 0, 0.7);
          border-radius: 50%;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          cursor: pointer;
          z-index: 2;
        }
      }
    }
  }
}
