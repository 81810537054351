.wrapper {
  display: flex;
  flex-direction: column;
  gap: 60px;
  padding: 60px 0;
  font-family: "Golos Text", sans-serif;
  background-color: #F5F5F5;
  box-shadow: 0 0 0 100vmax #F5F5F5;
  clip-path: inset(0 -100vmax);
  .head {
    display: flex;
    .title {
      flex: 1;
      font-size: 44px;
      font-weight: 600;
      line-height: 52.8px;
      text-align: left;
    }
    .button {
      border: none;
      background: rgba(148, 105, 227, 1);
      padding: 8px 22px;
      border-radius: 4px;
      width: fit-content;
      font-size: 15px;
      font-weight: 400;
      line-height: 28px;
      text-align: center;
      height: fit-content;
      color: white;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .title {
      font-size: 24px;
      font-weight: 500;
      line-height: 28.8px;
      text-align: left;
    }
    .text {
      font-size: 18px;
      font-weight: 400;
      line-height: 25.2px;
      text-align: left;
    }
  }
}
