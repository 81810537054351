.wrapper {
  clip-path: inset(0 -100vmax);
  box-shadow: 0 0 0 100vmax rgba(25, 25, 25, 1);
  background-color: rgba(25, 25, 25, 1);
  padding-top: 76px;
  display: flex;
  align-items: center;
  gap: 103px;
  font-family: "Golos Text", sans-serif;
  * {
    color: white;
    user-select: none;
  }
  .img {
    height: 605px;
    img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
  }
  .content {
    width: 650px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    .title {
      font-size: 44px;
      font-weight: 600;
      line-height: 52.8px;
      text-align: left;
    }
  }
}
