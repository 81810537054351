.wrapper {
  padding: 80px 0;
  box-shadow: 0 0 0 100vmax rgba(25, 25, 25, 1);
  clip-path: inset(0 -100vmax);
  background-color: rgba(25, 25, 25, 1);
  display: flex;
  flex-direction: column;
  gap: 56px;
  font-family: "Golos Text", sans-serif;
  *{
    user-select: none;
  }
  .head {
    display: flex;
    align-items: center;
    color: rgba(255, 255, 255, 1);
    .title_wrapper{
      display: flex;
      flex-direction: column;
      gap: 28px;
      flex: 1;
      .title {
        font-size: 44px;
        font-weight: 600;
        line-height: 52.8px;
        text-align: left;
        text-wrap: balance;
        line-clamp:2;
        -webkit-line-clamp:2;
      }
      .text {
        font-size: 18px;
        font-weight: 400;
        line-height: 25.2px;
        text-align: left;
      }
    }
 
  }
  .content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 32px;
    column-gap: 24px;
    .card {
      display: flex;
      align-items: center;
      gap: 16px;
      .block {
        display: flex;
        flex-direction: column;
        gap: 4px;
        .name {
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          font-weight: 600;
          line-height: 20.8px;
          text-align: left;
        }
        .subtitle {
          color: rgba(212, 210, 213, 1);
          font-size: 12px;
          font-weight: 400;
          line-height: 14.4px;
          text-align: left;
        }
      }
    }
  }
}
